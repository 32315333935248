import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage: React.FC = () => {
  return (
    <>
      <SEO title={"Page Not Found"} description={"Page Not Found"} />
      <Layout allowTransparentHeader={true}>
        <Wrapper>
          <StaticImage
            style={{ height: "100%" }}
            src={"../images/landingpage_header.jpg"}
            alt={"Hero background"}
            objectPosition={"0% 50%"}
          />
          <Overlay>
            <h2>Oops! Looks like you’re lost!</h2>
            <h1>404</h1>
            <h3>
              Don’t be afraid, just return to the <Link to={"/"}>homepage</Link>
            </h3>
          </Overlay>
        </Wrapper>
      </Layout>
    </>
  );
};

export default NotFoundPage;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  background-color: var(--color-dark-blue);
  overflow: hidden;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0 var(--gutter);
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.65) 0%,
      rgba(0, 0, 0, 0) 50%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, var(--color-dark-blue) 100%),
    rgba(19, 14, 102, 0.46);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;

  h2,
  h3 {
    font-weight: 600;
  }
  h1 {
    font-weight: 600;
    /* font-size: min(max(14rem, calc(14rem + ((1vw - 0.48rem) * 8.3333))), 20rem); */
    font-size: 14rem;
    @media (min-width: 480px) {
      font-size: calc(14rem + ((1vw - 0.48rem) * 8.3333));
      min-height: 0vw;
    }
    @media (min-width: 1200px) {
      font-size: 20rem;
    }
    min-height: 0vw;
    margin: 32px 0 32px 0;
  }
  a {
    font-weight: 600;
    /* font-size: min(max(2rem, calc(2rem + ((1vw - 0.48rem) * 2.2222))), 3.6rem); */
    font-size: 2rem;
    @media (min-width: 480px) {
      font-size: calc(2rem + ((1vw - 0.48rem) * 2.2222));
      min-height: 0vw;
    }
    @media (min-width: 1200px) {
      font-size: 3.6rem;
    }
    min-height: 0vw;
    color: var(--color-orange);
    text-decoration: underline;
    transition: 0.2s;
    &:hover {
      color: var(--color-orange-hovered);
    }
  }
`;
